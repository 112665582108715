<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Visualizar Cliente</h4>
          <div class="page-title-right">
            <b-button
              variant="warning"
              @click.prevent="$router.push({ name: 'editar-cliente', params:{id: $route.params.id} })"
            >
              <i class="fas fa-edit mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Editar Cliente</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="info"
              @click.prevent="$router.push({ name: 'clientes' })"
            >
              <i
                class="bx bx-list-ol font-size-16 align-middle mr-0 mr-md-2"
              ></i>
              <span class="d-none d-md-inline-block">Listar Clientes</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="success"
              @click.prevent="$router.push({ name: 'cliente' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-0 mr-md-2"></i>
              <span class="d-none d-md-inline-block">Adicionar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="error">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center text-danger my-2">
              <h5 class="mb-3">Erro ao carregar dados!</h5>
              <img width="250" src="@/assets/images/error-img.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="customer == null">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-5">
            <h5 class="mb-3">Item não encontrado!</h5>
            <img width="250" src="@/assets/images/not-data.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12 col-xl-4">
        <div class="card">
          <div class="card-body text-left">
            <h4 class="card-title mb-3">Dados Pessoais</h4>
            <hr />
            <div class="text-center w-100 my-3">
              <img
                class="rounded-circle avatar-xl"
                 :src="customer.avatar"
                 v-if="customer.avatar && customer.avatar != '' && customer.avatar != null"
                alt
              />
              <img
              v-else
                class="rounded-circle avatar-xl"
                 src="@/assets/images/notimg.jpg"
                alt
              />
            </div>
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">Nome:</th>
                    <td>{{customer.name}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Telefone:</th>
                    <td>{{customer.phone}}</td>
                  </tr>
                  <tr>
                    <th scope="row">E-mail:</th>
                    <td>{{customer.email}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Endereço:</th>
                    <td>{{customer.street}}, {{customer.number}}, {{customer.district}}, {{customer.city}} - {{customer.state}} / {{customer.cep}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body text-left">
            <h4 class="card-title mb-3">Endereço de Entrega</h4>
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">Estado:</th>
                    <td>{{customer.delivery_state}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Cidade:</th>
                    <td>{{customer.delivery_city}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rua/Avenida:</th>
                    <td>{{customer.delivery_street}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Número:</th>
                    <td>{{customer.delivery_number}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bairro:</th>
                    <td>{{customer.delivery_district}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Complemento:</th>
                    <td>{{customer.delivery_district}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-xl-8">
        <div class="row">
          <div
            v-for="stat of statData"
            :key="stat.id"
            class="col-lg-12 col-xl-4"
          >
            <Stat :icon="stat.icon" :title="stat.title" :tipo="stat.tipo" :value="stat.value" />
          </div>
          <div class="col-12">
            <div class="card text-left h-100">
              <div class="card-body">
                <h4 class="card-title mb-4">Pedidos</h4>
                <div class="row" v-if="sales.length <= 0">
                <div class="col-12">
                    <div class="card text-center">
                    <div class="card-body py-5">
                        <h5 class="mb-3">Itens não encontrados!</h5>
                        <img width="250" src="@/assets/images/not-data.svg" />
                    </div>
                    </div>
                </div>
                </div>
                <Sales v-else :sales="sales" :tipo="1" />
              </div>
            </div>
          </div>
          <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner" class="text-info"></div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Stat from '@/components/widgets/stat'
import Sales from '@/components/widgets/sales'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    Stat,
    Sales,
    InfiniteLoading
  },
  data() {
    return {
      loading: true,
      error: false,
      customer: null,
      statData: [
        {
          id: 1,
          tipo: 0,
          icon: 'bx bx-shopping-bag',
          title: 'Último 30 dias',
          value: 0,
        },
        {
          id: 2,
          tipo: 0,
          icon: 'bx bx-shopping-bag',
          title: 'Total de Compras',
          value: 0,
        },
        {
          id: 3,
          tipo: 1,
          icon: 'bx bx-shopping-bag',
          title: 'Quantidade de Pedidos',
          value: 0,
        },
      ],
      filtro:{
          limit: 20,
          page: 1,
      },
      sales: [],
    }
  },
  mounted() {
    if (this.$route.params.id <= 0) {
      this.loading = false
      this.error = true
    }
    this.findCustomer()
  },
  methods: {
    ...mapActions('customer', ['ActionCustomerFind']),
    ...mapActions('sale', ['ActionSaleAll', 'ActionSaleUpdate']),
    async infiniteHandler($state) {
      try {
          this.filtro.customer_id = this.$route.params.id
        await this.ActionSaleAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.sales.push(...res.data.data.list)
            $state.loaded()
          } else {
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.sales = []
        $state.complete()
      }
    },
    async findCustomer() {
      try {
        await this.ActionCustomerFind(this.$route.params.id).then((res) => {
          this.customer = res.data.data
          this.statData[0].value = res.data.data.total_compras_mes
          this.statData[1].value = res.data.data.total_compras
          this.statData[2].value = res.data.data.total_sales
          this.loading = false
          this.error = false
        })
      } catch (e) {
        this.loading = false
        this.error = true
        this.customer = null
      }
    },
  },
}
</script>
